import {useState} from 'react'
import {set} from 'lodash'

export default function useForm({initialValues, storageKey} = {}) {
  const load = () => {
    if (!storageKey) return undefined
    try {
      return JSON.parse(localStorage.getItem(storageKey))
    } catch (e) {
      localStorage.removeItem(storageKey)
    }
  }
  const [values, setValues] = useState(load() || initialValues || {})

  const setValuesAndStore = (values) => {
    if (storageKey) localStorage.setItem(storageKey, JSON.stringify(values))
    setValues(values)
  }

  const handleChange = (event) => {
    const value = event.target.value
    const name = event.target.name
    const updated = {...values}
    set(updated, name, value)
    setValuesAndStore(updated)
  }

  const setValue = (k, v) =>
    setValues({...values, [k]: v})

  const addValues = (toAdd) =>
    setValues({...values, ...toAdd})

  return {
    handleChange,
    setValues,
    addValues,
    setValue,
    values,
  }
}
