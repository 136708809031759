import {parseExchangeOrders, parseExecutionModel, parseOrder, parseRiskLayer, parseRun, parseTrade} from './Parsers'
import {useContext, useRef} from 'react'
import {ClientContext} from '../context/ClientContext'
import {useError} from '../hooks/useError'
import {anySource} from '../util/text'

const useAltoClient = () => {
  const alto = {}
  const controller = useRef(new AbortController())
  const {axios, useRefreshToken, clearTokens, token, authenticate} = useContext(ClientContext)
  alto.axios = axios
  const {handleError} = useError()

  alto.useRefreshToken = useRefreshToken
  alto.authenticate = authenticate
  alto.accessToken = token

  alto.logout = () => {
    clearTokens()
    return post('auth', 'logout')
  }

  /////// CONSTRUCT URLS ///////
  const apiUrl = (api, endpoint) => `/api/${api}/${endpoint}`.replaceAll('//', '/')
  const upsertUrl = (api, endpoint, id) => `${apiUrl(api, endpoint)}${id ? `/${id}` : ''}`

  ////// BASIC METHODS ////////
  const get = (api, endpoint, config = {}, parse) => {
    return handleError(async () => {
      const url = apiUrl(api, endpoint)
      const {data} = await axios.get(url, {...config, signal: controller.current.signal})
      return parse ? parse(data) : data
    })
  }
  alto.get = get
  const getArray = (api, endpoint, config = {}, parse) => {
    return handleError(async () => {
      const url = apiUrl(api, endpoint)
      const {data} = await axios.get(url, {...config, signal: controller.current.signal})
      return parse ? data.map(parse) : data
    })
  }
  alto.getArray = getArray
  const post = (api, endpoint, body, config = {}) => {
    return handleError(async () => {
      const url = apiUrl(api, endpoint)
      const {data} = await axios.post(url, body, {...config, signal: controller.current.signal})
      return data
    })
  }
  alto.post = post
  const put = (api, endpoint, body, config = {}) => {
    return handleError(async () => {
      const url = apiUrl(api, endpoint)
      const {data} = await axios.put(url, body, {...config, signal: controller.current.signal})
      return data
    })
  }
  alto.put = put
  const _delete = (api, endpoint, config = {}) => {
    return handleError(async () => {
      const {data} = await axios.delete(apiUrl(api, endpoint), {...config, signal: controller.current.signal})
      return data
    })
  }
  alto.delete = _delete
  const upsert = (api, endpoint, object, config = {}) => {
    return handleError(async () => {
      const url = upsertUrl(api, endpoint, object.id)
      const {data} = await axios.post(url, object, {...config, signal: controller.current.signal})
      return data
    })
  }
  alto.upsert = upsert

  // @formatter:off
  ////// LOAD SINGLE OBJECTS //////
  alto.loadBacktestRun            = (id                  ) => get('backtest',   `/backtestrun/${id}`)
  alto.loadEntity                 = (id                  ) => get('auth',       `/entity/${id}`)
  alto.loadOrder                  = (id                  ) => get('execution',  `/order/${id}`, {}, parseOrder)
  alto.loadExecutionModel         = (id                  ) => get('execution',  `/model/${id}`, {}, parseExecutionModel)
  alto.loadStrategy               = (id                  ) => get('strategy',   `/strategy/${id}`)
  alto.loadStrategyVariant        = (id, dataset         ) => get('strategy',   `/variant/${id}/${dataset}`)
  alto.loadStrategyRun            = (id                  ) => get('strategy',   `/strategyrun/${id}`, {}, parseRun)
  alto.loadRunSummary             = (id                  ) => get('strategy',   `/strategyrun/${id}/summary`)
  alto.loadRunSnapshot            = (id, params          ) => get('strategy',   `/strategyrun/${id}/snapshot`, {params})
  alto.loadRiskLayer              = (id                  ) => get('execution',  `/risklayer/${id}`)
  alto.loadSecret                 = (type, name          ) => get('infra',      `/secret/${type}/${name}`)
  alto.loadSnapshot               = (id                  ) => get('backtest',   `/snapshot/${id}`)
  alto.loadStorage                = (path                ) => get('infra',      `/storage/${path.replaceAll('/', '%2F')}`)
  alto.loadTicker                 = (dataset             ) => get('marketdata', `/ticker/${dataset}`)
  alto.loadOrderBook              = (dataset, params     ) => get('marketdata', `/book/${dataset}`, {params})
  alto.loadTrade                  = (id                  ) => get('execution',  `/trade/${id}`, {}, parseTrade)
  alto.loadTradeOrders            = (id                  ) => get('execution',  `/trade/${id}/orders`, {}, parseExchangeOrders)
  alto.loadTrial                  = (id                  ) => get('backtest',   `/trial/${id}`)
  alto.loadExchangeBalances       = (xtp, params         ) => get('execution',  `/external/${xtp}/balances`, {params})
  alto.loadExchangeAccount        = (xtp, params         ) => get('execution',  `/external/${xtp}/account`, {params})
  alto.loadExchangeLoans          = (xtp, params         ) => get('execution',  `/external/${xtp}/loans`, {params})
  alto.loadExchangeMetadata       = (xtp, params         ) => get('execution',  `/external/${xtp}/metadata`, {params})
  alto.loadExchangeOrders         = (xtp, dataset, params) => get('execution',  `/external/${xtp}/orders/${dataset}`, {params})
  alto.loadExchangeOpenOrders     = (xtp, dataset, params) => get('execution',  `/external/${xtp}/open/${dataset}`, {params})
  alto.loadAtfResult              = (asOf                ) => get('infra',      `/atf/${asOf}`)
  alto.loadAtfStatus              = (asOf                ) => get('infra',      `/atf/${asOf}/status`)
  alto.loadXtp                    = (id                  ) => get('execution',   `/xtp/${id}`)
  alto.loadExecutionMode          = (id                  ) => get('execution',   `/model/${id}`)
  alto.loadWorkspace              = (id                  ) => get('backtest',    `/workspace/${id}`)

  ////// CREATE / UPDATE OBJECTS //////
  alto.upsertXtps                 = (xtp                   ) => upsert('execution', '/xtp', xtp)
  alto.upsertEntity               = (entity                ) => upsert('auth',      '/entity', entity)
  alto.upsertExecutionModel       = (model                 ) => upsert('execution', '/model', model)
  alto.upsertStrategy             = (strategy              ) => upsert('strategy',  '/strategy', strategy)
  alto.upsertWorkspace            = (workspace             ) => upsert('backtest',  '/workspace', workspace)
  alto.upsertStrategyRun          = (run                   ) => upsert('strategy',  '/strategyrun', run)
  alto.upsertSnapshot             = (snapshot              ) => upsert('backtest',  '/snapshot', snapshot)
  alto.upsertTrial                = (trial                 ) => upsert('backtest',  '/trial', trial)
  alto.upsertStorage              = (path, data            ) => upsert('infra',     `/storage/${path.replaceAll('/', '%2F')}`, data)
  alto.upsertSecret               = (type, name, value     ) => upsert('infra',     `/secret/${type}/${name}`, {value})
  alto.upsertStrategyVariant      = (id, source, variant   ) => upsert('strategy',  `/variant/${id}/${source}` , variant)
  alto.upsertBag                  = (data                  ) => post('marketdata', '/bag', data)
  alto.deleteFund                 = (id                    ) => _delete('execution', `/fund/${id}`)
  alto.upsertRiskLayer            = (id, data              ) => post('execution', `/risklayer/${id}`, data)
  alto.upsertTickSize             = (dataset, date, params ) => post('marketdata', `/universe/ticksize/${dataset}/${date}`, params)

  ////// DELETE OBJECTS //////
  alto.deleteXtp                  = (id              ) => _delete('execution', `/xtp/${id}`)
  alto.deleteEntity               = (id              ) => _delete('auth',      `/entity/${id}`)
  alto.deleteStrategy             = (id              ) => _delete('strategy',  `/strategy/${id}`)
  alto.deleteStrategyRun          = (id              ) => _delete('strategy',  `/strategyrun/${id}`)
  alto.deleteSnapshot             = (id              ) => _delete('backtest',  `/snapshot/${id}`)
  alto.deleteTrial                = (id              ) => _delete('backtest',  `/trial/${id}`)
  alto.deleteSecret               = (type, name      ) => _delete('infra',     `/secret/${type}/${name}`)
  alto.deleteStorage              = (path            ) => _delete('infra',     `/storage/${path.replaceAll('/', '%2F')}`)
  alto.cancelExchangeOrder        = (xtp, dataset, id) => _delete('execution',  `/external/${xtp}/orders/${dataset}/${id}`)
  alto.deleteJob                  = (name            ) => _delete('infra',     `/job/${name}`)

  ////// LOAD MULTIPLE OBJECTS //////
  alto.loadXtps                   = (params = {}                        ) => get('execution',       `xtp`, {params})
  alto.loadExecutionModels        = (params = {}                        ) => get('execution',       `model`, {params})
  alto.loadEntities               = (params = {}                        ) => get('auth',            `entity`, {params})
  alto.loadLedgers                = (id, params = {}                    ) => get('execution',       `ledger/${id}`, {params})
  alto.loadStrategies             = (params = {}                        ) => get('strategy',        `strategy`, {params})
  alto.loadStrategyRuns           = (params = {}                        ) => get('strategy',        `strategyrun`, {params})
  alto.loadSnapshots              = (params = {}                        ) => get('backtest',        `snapshot`, {params})
  alto.loadTrials                 = (params = {}                        ) => get('backtest',        `trial`, {params})
  alto.loadWorkspaces             = (params = {}                        ) => get('backtest',        `workspace`, {params})
  alto.loadSecrets                = (params = {}                        ) => get('infra',           `secrets`, {params})
  alto.loadTickers                = (source                             ) => get('marketdata',      `ticker/all/${source}`)
  alto.loadTickSizes              = (                    ) => get('marketdata',  `/universe/ticksize/`)
  alto.loadIndicators             = (params = {}                        ) => get('backtest',        `ta/indicators`, {params})
  alto.loadSignals                = (params = {}                        ) => get('backtest',        `ta/signals`, {params})
  alto.loadAtfResults             = (asOf                               ) => get('infra',           `atf`, {params: {asOf}})
  alto.loadHeartbeats             = (asOf                               ) => get('infra',           `hb`, {params: {asOf}})
  alto.loadMetrics                = (id, params = {}                    ) => get('strategy',        `metric/${id}`, {params})
  alto.loadOrders                 = (params = {}                        ) => getArray('execution',  `order`, {params}, parseOrder)
  alto.loadTrades                 = (params = {}                        ) => getArray('execution',  `trade`, {params}, parseTrade)
  alto.loadUniverse               = (params = {}                        ) => getArray('marketdata', `universe`, {params})
  alto.loadCandles                = (dataset, params={}                 ) => getArray('marketdata', `candle/${dataset}`, {params})
  alto.loadCandlesResampled       = (dataset, resampling, params={}     ) => getArray('marketdata', `candle/${dataset}/${resampling}`, {params})
  alto.loadJobs                   = (params                             ) => getArray('infra',      '/job', params)
  alto.loadRiskLayers             = (params                            ) => getArray('execution',  '/risklayer', {params}, parseRiskLayer)

  ////// OTHERS //////
  alto.whoAmI                     = (                ) => get('auth',       'whoami')
  alto.postLlm                    = (data            ) => post('infra',     'llm', data)
  alto.sliceMetrics               = (id, params = {} ) => get('strategy',   `/metric/${id}/slice`, {params})
  alto.executeBacktest            = (backtest, config) => post('backtest',  '/backtestrun/execute', backtest, config)
  alto.latestLedgers              = (id, params = {} ) => get('execution',  `/ledger/${id}/latest`, {params})
  alto.openInstruction            = (instruction     ) => post('execution', '/order/open', instruction)
  alto.closeInstruction           = (instruction     ) => post('execution', '/order/close', instruction)
  alto.adjustRiskAllocation       = (runId, data     ) => post('execution', `/ledger/${runId}/allocation`, data)
  alto.createJob                  = (params          ) => post('infra', '/job', params)
  alto.refreshTrade               = (id              ) => post('execution', `/trade/${id}/check`)
  alto.actionOrder                = (params          ) => post('execution', '/order', params)

  /// TEST EXCHANGE ////
  alto.postOrderTestExchange                  = (params)           => post('exchange', '/order', params)
  alto.toggleSimPaused                        = (paused)           => post('exchange', '/sim/toggle', {params: {paused}})
  alto.loadSimPaused                          = ()                 => get('exchange', '/sim')
  alto.cancelAllTestExchangeOrders            = (params)           => _delete('exchange', `/order`, {params})
  alto.depositTestExchange                    = (params)           => post('exchange', '/account/deposit', params)
  alto.withdrawTestExchange                   = (params)           => post('exchange', '/account/withdraw', params)
  // @formatter:on

  alto.loadStrategyOrVariant = async (id, dataset) => {
    let obj = await alto.loadStrategy(id)
    if (!dataset || !obj) return obj
    if (obj.variants[dataset]) obj.params = obj.variants[dataset]
    if (obj.variants[anySource(dataset)]) obj.params = obj.variants[anySource(dataset)]
    return obj
  }

  alto.loadParameters = async (id, dataset) => {
    let obj = null
    if (id.startsWith('str_')) obj = await alto.loadStrategyOrVariant(id, dataset)
    if (id.startsWith('run_')) obj = await alto.loadStrategyRun(id)
    if (id.startsWith('bkr_')) obj = await alto.loadBacktestRun(id)
    if (id.startsWith('sna_')) obj = await alto.loadSnapshot(id)
    if (id.startsWith('tri_')) obj = await alto.loadTrial(id)
    if (!obj) throw Error(`Unable to load parameters for ${id}`)
    return obj.params
  }

  alto.closeTrade = async (withConfirmation, trade) => {
    await withConfirmation('Are you sure you want to manually close this trade?', async () => {
      const params = {ownerId: trade.ownerId, tradeId: trade.id, expiry: null, ref: 'Manual Close', tick: 0}
      console.info(await axios.post('/api/execution/order/close', params))
    })
  }

  return alto
}

export default useAltoClient
